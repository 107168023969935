import React from "react"
import { Link } from "gatsby"

/* TODO add typescript to project

type Fallback = {
  src: string
  srcSet: string
  sizes: string
}

type Images = {
  sources: string[]
  fallback: Fallback
}

type Placeholder = {
  fallback: string
}

type GatsbyImageData ={
  images: Images
  layout: string
  placeholder: Placeholder
  width: number
  height: number
}

type CoverImage = {
  gatsbyImageData: GatsbyImageData
}

type PostPreviewProps = {
  title: string
  coverImage : CoverImage
  excerpt: string
  authorFullName: string
  slug: string
}
*/

export default function PostPreview({
  title,
  coverImage,
  excerpt,
  slug,
  affiliateLinkCta,
  authorFullName,
}) {
  return (
    <>
      <div className="bg-zone-3 p-4 border shadow-md rounded-md">
        <img
          src={coverImage.gatsbyImageData.images.fallback.src}
          className="object-cover rounded w-full"
          alt={title}
        />
        <div className="pt-4 text-zone-1">{authorFullName}</div>
        <div className="font-bold text-3xl pt-4">
          <Link to={`/blog/${slug}`} className="hover:underline">
            {title}
          </Link>
        </div>
        <div className="pt-4 text-zone-1 flex gap-3">
          <div className="rounded-md px-4 py-3 bg-zone-12 hover:bg-zone-9 min-w-[100px] text-center shadow-md cursor-pointer active:shadow active:relative active:top-[1px] select-none">
            <Link to={`/blog/${slug}`}>{affiliateLinkCta}</Link>
          </div>
          <div className="text-zone-1">{excerpt}</div>
        </div>
      </div>
    </>
  )
}
