import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import CoverImage from "../../components/CoverImage"
import Layout from "../../components/Layout"
import PostPreview from "../../components/PostPreview"
import PostSidebar from "../../components/PostSidebar"
import Seo from "../../components/Seo/Seo"

const Blog = () => {
  const data = useStaticQuery(graphql`
    query {
      allPosts: allDatoCmsPost(sort: { fields: date, order: DESC }, limit: 20) {
        edges {
          node {
            id
            affiliateLinkCta
            content
            date
            excerpt
            title
            slug
            seoMeta {
              description
              title
            }
            author {
              fullName
            }
            coverImage {
              gatsbyImageData(
                imgixParams: {
                  auto: "format"
                  fit: "crop"
                  h: "330"
                  w: "660"
                  colors: 10
                  mask: "corners"
                  cornerRadius: "10,10,10,10"
                }
                placeholder: BLURRED
              )
            }
            affiliateLink {
              affiliateLink
            }
          }
        }
      }
      blogPage: allDatoCmsMainPage(filter: { slug: { eq: "blog" } }) {
        nodes {
          content
          title
          slug
          pageSeoMeta {
            description
            title
          }
          sideMenuTitle
          headerImage {
            gatsbyImageData(imgixParams: { h: "700", w: "1000" })
          }
        }
      }
    }
  `)

  const posts = data.allPosts.edges

  const renderedPosts = posts.map((item, index) => {
    return (
      <PostPreview
        key={index}
        image={item.node.coverImage}
        slug={item.node.slug}
        alt={item.node.title}
        title={item.node.title}
        affiliateLinkCta={item.node.affiliateLinkCta}
        authorFullName={item.node.author.fullName}
        coverImage={item.node.coverImage}
        excerpt={item.node.excerpt}
      />
    )
  })

  const blogPage = data.blogPage.nodes[0]

  const meta_title = blogPage.pageSeoMeta.title
  const meta_description = blogPage.pageSeoMeta.description

  return (
    <Layout>
      <Seo title={meta_title} description={meta_description} />
      <main>
        <div>
          <CoverImage title={blogPage.title} image={blogPage.headerImage} />
          <div className="bg-zone-1 text-zone-1 flex justify-center px-4 md:px-0">
            <div className="w-[900px] py-8 flex flex-col gap-8 md:flex-row">
              <div className="flex-1 flex flex-col gap-4">{renderedPosts}</div>
              <PostSidebar />
            </div>
          </div>
        </div>
      </main>
    </Layout>
  )
}

export default Blog
